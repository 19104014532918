<template>
    <section class="home-image overflow-hidden">
        <div class="position-relative container d-flex justify-content-center align-items-center py-5 h-100">
            <div class="position-absolute model left"
                 data-aos="fade-right"
                 data-aos-duration="1000">
                <img alt="3激光超零感脫毛"
                     src="../../../assets/campaign/elysion-pro-comb/model-left.png">
            </div>
            <div class="position-absolute model right"
                 data-aos="fade-left"
                 data-aos-duration="1000">
                <img alt="3激光超零感脫毛"
                     src="../../../assets/campaign/elysion-pro-comb/model-right.png">
            </div>

            <div class="d-none d-md-block headings py-5">
                <h4 class="text-center mb-0"
                    data-aos="fade-up"
                    data-aos-duration="1000">
                    Elysion-Pro COMB 810-940-1060nm
                </h4>
                <h1 class="display-2 font-weight-bolder text-center font-noto-serif-tc"
                    data-aos="fade-up"
                    data-aos-duration="1000">
                    3激光超零感脫毛
                </h1>
            </div>

            <div class="d-block d-md-none headings py-5">
                <h5 class="text-center mb-0"
                    data-aos="fade-up"
                    data-aos-duration="1000">
                    Elysion-Pro COMB 810-940-1060nm
                </h5>
                <h1 class="display-3 font-weight-bolder text-center font-noto-serif-tc"
                    data-aos="fade-up"
                    data-aos-duration="1000">
                    3激光超零感脫毛
                </h1>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: "ElysionProCombHome1",
        data () {
            return {

            };
        },
        computed: {

        },
        methods: {

        },
    }
</script>

<style lang="scss" scoped>
    @import "~bootstrap/scss/functions";
    @import "~bootstrap/scss/variables";
    @import "~bootstrap/scss/mixins/_breakpoints";

    @import "./variables.scss";

    .home-image {
        height: 65vh;

        @include media-breakpoint-up(sm) {
            height: 60vh;
        }

        background-color: $background-color;
        color: $text-color;

        .model {
            bottom: -67.5%;

            & > img {
                height: 70vh;
            }

            &.left {
                right: 65%;

                & > img {
                    height: 75vh;
                }
            }

            &.right {
                left: 65%;
            }
        }

        .headings {
            z-index: 1;
        }
    }
</style>
